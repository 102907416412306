import { defineStore } from 'pinia'
import { v1 as uuidv1 } from 'uuid'

export const useSnackbarsStore = defineStore('snackbars', {
  state: () => ({
    items: []
  }),
  actions: {
    new(snackbar) {
      snackbar.id = snackbar.id || uuidv1()
      snackbar.timeout = snackbar.timeout || 3000
      this.items.push(snackbar)
    },
    dismiss(id) {
      this.items = this.items.filter((snackbar) => snackbar.id !== id)
    },
    success(message) {
      this.new({ message, type: 'success' })
    },
    info(message) {
      this.new({ message, type: 'info' })
    },
    warn(message) {
      this.new({ message, type: 'warning' })
    },
    error(error) {
      let message = error?.message || error?.error || error
      if (!message) { return }
      if (typeof message !== 'string') {
        console.error(message)
        message = 'An error occurred'
      }
      // if there is already a snackbar with the same message, don't show it again
      if (this.items.find(x => x.message === message)) { return }
      this.new({ message, type: 'error', timeout: 10000 })
    }
  }
})
