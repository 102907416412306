/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  defaults: {
    global: {
      ripple: false,
    },
    VBtn: {
      class: 'text-none'
    }
  },
  display: {
    mobileBreakpoint: 'md',
    // THESE THRESHOLD UPDATES DO NOT PROPAGATE ATM TO CSS MEDIA
    // thresholds: {
    //   xs: 0,
    //   sm: 340, // 600,
    //   md: 768, // 960,
    //   lg: 1280,
    //   xl: 1920,
    //   xxl: 2560
    // },
  },
  theme: {
    themes: {
      light: {
        colors: {
          // background: '#FFFFFF',
          surface: '#FFFFFF',
          // 'surface-bright': '#FFFFFF',
          // 'surface-light': '#EEEEEE',
          // 'surface-variant': '#424242',
          // 'on-surface-variant': '#EEEEEE',
          primary: '#C11CDE',
          // primary: '#963EA5',
          secondary: '#AF75BC',
          'secondary-lighten': '#E2DCF5',
          background: '#F5F5F5',
          error: '#aa0000',
          success: '#11B02A',
          warning: '#EDB600',
          gray: '#F2F5F7',
          black: '#0A000B',
        },
      },
    },
  },
})
