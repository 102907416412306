<template>
  <v-app :class="{ mobile: $vuetify.display.smAndDown }">
    <router-view />
    <snackbars />
    <reload-prompt />
    <development v-if="mode !== 'production' && whichApp === 'app'" />
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { mode } from '@/config'
import { useRouter } from 'vue-router'
const { whichApp } = useRouter()

onMounted(() => {
  document.getElementsByTagName('html')[0]?.classList?.remove('loading')
})

</script>
