import { defineStore } from 'pinia'
import posthog from 'posthog-js'
import memberstack from '@/services/memberstack'
import api from '@/services/api'
import { mode } from '@/config'
let pollTimeout

export const useAppStore = defineStore('app', {
  state: () => ({
    member: null,
    billingLoading: false,
    account: null,
    supermembers: mode !== 'production' ? [] : undefined,
    supermemberId: null,
    showOptionalNextStepsDialog: false,
    showVerifyOwnershipPrompt: false,
    showVerifyOwnershipDialog: false,
    showVerifyOwnershipSuccessPopup: false,
    showMyPlanDialog: false,
    selectedRemoveManuallyLink: null,
  }),
  actions: {
    async initialize() {
      posthog.opt_in_capturing()
      if (!window.localStorage.getItem('_ms-mid')) { return }
      if (!this.member) {
        this.member = await memberstack.getCurrentMember().then(({ data }) => data)
        if (!this.member) {
          posthog.reset()
          return
        }
      }
      mode !== 'production' && await this.initializeSupermembers()
      const account = await this.pollMemberJson()
      posthog.identify(this.member.id, {
        // account id in our backend, above is memberstack id
        accountId: account?.objectId,
        name: account?.name || this.member.customFields.name,
        email: account?.email || this.member.auth.email,
        // if any subscription this is boolean True of False
        isSubscribed: account?.isSubscribed,
        // either null for free, or 'small' | 'medium' | 'large'
        plan: account?.plan,
        // if account was verified this is a date
        verifiedAt: account?.verifiedAt,
        // if account has stripe customer id
        stripeCustomerId: account?.stripeCustomerId
      })
    },
    setMember({ member }) {
      this.member = member
      mode !== 'production' && setTimeout(this.initializeSupermembers, 1000)
      return this.pollMemberJson()
    },
    setMemberJson(memberJson) {
      this.account = memberJson
    },
    getPollWaitTime() {
      if (!this.account || !this.account.isSubscribed) {
        return 1000 + Math.random() * 1000
      }
      return 3000 + Math.random() * 5000
    },
    async pollMemberJson() {
      clearTimeout(pollTimeout)
      if (!this.member) { return }
      if (mode !== 'production' && this.supermemberId) {
        await api.post('supermember-json')
          .then(this.setMemberJson)
          .catch(console.error)
        pollTimeout = setTimeout(this.pollMemberJson, this.getPollWaitTime())
        return this.account
      }
      await memberstack.getMemberJSON()
        .then(({ data }) => data)
        .then(this.setMemberJson)
        .catch(console.error)
      mode !== 'production' && console.log('pollMemberJson', this.account)
      pollTimeout = setTimeout(this.pollMemberJson, this.getPollWaitTime())
      return this.account
    },
    async login({ email, password }) {
      await memberstack.loginMemberEmailPassword({
        email,
        password
      }).then(({ data }) => data).then(this.setMember)
    },
    async loginWithProvider(provider) {
      await memberstack.loginWithProvider({
        provider
      }).then(({ data }) => data).then(this.setMember)
    },
    async signupWithEmail({ name, email, password }, gclid) {
      await memberstack.signupMemberEmailPassword({
        email,
        password,
        customFields: { name, mode, gclid }
      }).then(({ data }) => data).then(this.setMember)
      mode !== 'production' && console.log('signed up with mode', mode)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up_complete',
        email,
        eventLabel: 'email'   //variable depends on which button is pressed
      })
      // attempt to send verification email if necessary
      !this.member.verified && await memberstack.sendMemberVerificationEmail()
    },
    async signupWithProvider(provider, gclid) {
      await memberstack.signupWithProvider({
        provider,
        customFields: { mode, gclid }
      }).then(({ data }) => data).then(this.setMember)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up_complete',
        provider,
        eventLabel: 'provider'   //variable depends on which button is pressed
      })
      mode !== 'production' && console.log('signed up with mode', mode)
    },
    async sendMemberVerificationEmail() {
      const { data: { success } } = await memberstack.sendMemberVerificationEmail()
      if (!success) { throw new Error('Failed to send verification email') }
    },
    async updateNiches(niches) {
      clearTimeout(pollTimeout)
      await api.post('/update-niches', { niches }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async addLink(link) {
      clearTimeout(pollTimeout)
      await api.post('/add-link', { link }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async removeLink(linkId) {
      if (this.account.links.length === 1) {
        return alert('You must have at least one link. Please add another link before removing this one.')
      }
      if (!confirm('Are you sure you want to remove this link?')) { return }
      clearTimeout(pollTimeout)
      await api.post('/remove-link', { linkId }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async addUsername(username) {
      clearTimeout(pollTimeout)
      await api.post('/add-username', { username }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async removeUsername(usernameId, dismissingRejected) {
      if (!dismissingRejected) {
        if (!confirm('Are you sure you want to remove this username?')) { return }
        // TODO check if platform username
        if (this.account.usernames.length === 1) {
          return alert('You must have at least one username. Please add another username before removing this one.')
        }
      }
      clearTimeout(pollTimeout)
      await api.post('/remove-username', { usernameId }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async confirmNoReddit() {
      clearTimeout(pollTimeout)
      // if (confirm('Are you sure you want to confirm that you do not have a Reddit account?')) {
      await api.post('/confirm-no-reddit').then(this.setMemberJson)
      // }
      this.pollMemberJson()
      return true
    },
    async confirmNoTwitter() {
      clearTimeout(pollTimeout)
      // if (confirm('Are you sure you want to confirm that you do not have a Twitter account?')) {
      await api.post('/confirm-no-twitter').then(this.setMemberJson)
      // }
      this.pollMemberJson()
      return true
    },
    async dismissOptionalNextSteps() {
      clearTimeout(pollTimeout)
      await api.post('/dismiss-optional-next-steps').then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async dismissVerifyOwnershipSuccessPopup() {
      clearTimeout(pollTimeout)
      await api.post('/dismiss-verify-ownership-success-popup').then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async getLeaksFound({ filters, pagination, skip }) {
      const hasNonDefaultFilters = Object.keys(filters).some(key => filters[key] !== null)
      // the leaksFoundCache -> has up to 50 items of the /leaks-found results
      const isOutsideCachedRange = (skip + pagination) > this.account.stats?.leaksFoundCache?.length
      if (hasNonDefaultFilters || isOutsideCachedRange) {
        return await api.post('/leaks-found', { filters, pagination, skip })
      }
      return {
        results: (this.account.stats?.leaksFoundCache || []).slice(skip, skip + pagination),
        count: this.account.stats?.found || 0
      }
    },
    async getTakedownRequests({ skip }) {
      return await api.post('/takedown-requests', { skip })
    },
    async submitTakedownRequest(form) {
      return await api.post('/submit-takedown-request', form)
    },
    async fetchDomainDmcaContact(link) {
      return await api.post('/domain-dmca-contact', { link })
    },
    async checkVerification() {
      clearTimeout(pollTimeout)
      await api.post('/verification-check').then(this.setMemberJson)
      if (this.account.verifiedAt) {
        this.showVerifyOwnershipPrompt = false
        this.showVerifyOwnershipSuccessPopup = true
      }
      this.pollMemberJson()
    },
    async startScan() {
      clearTimeout(pollTimeout)
      await api.post('/start-scan').then(this.setMemberJson)
      this.pollMemberJson()
    },
    setOptionalNextStepsDialog(value) {
      this.showOptionalNextStepsDialog = value
    },
    setVerifyOwnershipPrompt(value) {
      this.showVerifyOwnershipPrompt = value
    },
    setVerifyOwnershipDialog(value) {
      this.showVerifyOwnershipDialog = value
      this.showVerifyOwnershipPrompt = false
      this.showOptionalNextStepsDialog = false
    },
    setRemoveManuallyLink(item) {
      this.selectedRemoveManuallyLink = item
    },
    setMyPlanDialog(value) {
      this.showMyPlanDialog = value
    },
    getStripeCustomerSession() {
      if (!this.account.stripeCustomerId) { return null }
      return api.post('/stripe-customer-session', {
        customer: this.account.stripeCustomerId
      }).catch(() => {})
    },
    async goToBilling() {
      if (this.billingUrl || !this.account.stripeCustomerId) { return null }
      this.billingLoading = true
      try {
        const billingUrl = await api.post('/stripe-billing-portal-url', {
          customer: this.account.stripeCustomerId
        })
        window.location.href = billingUrl
      } catch (error) {
        this.billingLoading = false
        throw new Error('Failed to get billing portal URL to Stripe. Please contact us.')
      }
    },
    sendResetPasswordEmail({ email }) {
      return memberstack.sendMemberResetPasswordEmail({ email })
        .then(({ data }) => data)
        .catch((error) => {
          return error?.message
        })
    },
    async resetPassword({ token, password }) {
      const { data: { success } } = await memberstack.resetMemberPassword({
        token,
        newPassword: password
      })
      if (!success) { throw new Error('Invalid token') }
    },
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      await memberstack.logout()
      window.location.href = '/'
    },
    // SUPERMEMBERS
    async initializeSupermembers() {
      try {
        const { supermembers, supermemberId } = await api.post('/supermembers-initialize')
        this.supermembers = supermembers
        this.supermemberId = supermemberId
      } catch (error) {
        if (error.error?.startsWith?.('Account with memberstack.id')) {
          return this.logout()
        }
        throw error
      }
    },
    async changeSuperMember(id) {
      await api.post('/supermember-change-member', { id })
    },
  },
})
