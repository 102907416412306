<template>
  <v-btn
    style="position: fixed; top: 0; right: 0; z-index: 1005; border-radius: 0; border-bottom-left-radius: 1em;"
    :color="isSelf === null ? '' : isSelf ? 'info' : 'warning'"
    variant="elevated"
    class="text-h3"
    @click="dialog = true"
  >
    🚧
  </v-btn>
  <v-dialog
    v-model="dialog"
    max-width="720"
    persistent
    scrollable
  >
    <v-card rounded="xl">
      <v-card-title>
        🚧 {{ upperFirst(mode) }}
        <v-btn
          class="dialog-close"
          elevation="0"
          icon
          @click="dialog = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>This is the <code>{{ mode }}</code> environment for Bruqi.</p>
        <p v-if="!member">Once you have logged in, you can switch between members to view the app as that account.</p>
        <p>When viewing as a different account, the data is fetched from the backend server rather than memberstack. Therefore when testing please use the view as functionality only to check the UI but not any of the user related functions such as verifying email, updating a plan etc. Those should be tested without this view as mode.</p>
        <p><code>Current Account Id: {{ account?.objectId || 'NONE' }}</code></p>
        <p v-if="!isSelf">
          You can click the clear button in the field below to reset and view as the current account
        </p>
        <v-autocomplete
          v-if="supermembers.length && !changing"
          v-model="supermemberId"
          :items="supermembers"
          label="Select a user"
          variant="outlined"
          item-value="id"
          item-title="customFields.name"
          item-props
          :clearable="!isSelf"
          @update:modelValue="accountSelected"
        >
          <template #item="{ item, props }">
            <v-list-item v-bind="props">
              <template #append>
                {{ $filters.plan(props.plan) }}
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-progress-linear
          v-else-if="changing"
          indeterminate
          color="white"
          class="mt-12"
        />
      </v-card-text>
      <v-card-actions class="px-4">
        <v-btn
          v-if="member"
          size="large"
          @click="appStore.logout"
        >
          Logout
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from 'vue'
import { upperFirst } from 'lodash'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { mode } from '@/config'
const appStore = useAppStore()
const {
  member,
  account,
  supermembers,
  supermemberId
} = storeToRefs(appStore)
const dialog = ref(false)
const isSelf = computed(() => !member ? null : !supermemberId)
const changing = ref(false)
async function accountSelected(accountId) {
  if (changing.value) return
  changing.value = true
  await appStore.changeSuperMember(accountId)
  changing.value = false
  window.location.href = '/'
}
</script>

<style lang="scss">
.v-card-text p {
  margin-bottom: 1em;
}
</style>