export const mode = import.meta.env.VITE_MODE
export const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
export const memberstackAppId = import.meta.env.VITE_MEMBERSTACK_APP_ID
export const plans = [
  {
    plan: 'free',
    title: 'DIY',
    description: 'Made to assist creators in manual DIY removals.',
    features: [
      {
        title: '<b>Monthly</b> basic scans',
        allowed: true,
      },
      {
        title: 'DIY removals resources',
        allowed: true,
      },
      {
        title: 'No automatic removals',
        allowed: false,
      },
    ],
  },
  {
    plan: 'small',
    title: 'Starter',
    description: 'Made to help small creators without breaking the bank.',
    features: [
      {
        title: '<b>Weekly</b> scans & removals',
        allowed: true,
      },
      {
        title: 'All usernames',
        allowed: true,
      },
      {
        title: 'Unlimited removals on Google and 1.7M+ Websites',
        allowed: true,
      },
    ],
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_SMALL_MONTHLY,
      price: 29
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_SMALL_YEARLY,
      price: 24
    }
  },
  {
    plan: 'medium',
    title: 'Creator Pro',
    description: 'Strong protection designed for established creators.',
    features: [
      {
        title: '<b>Daily</b> scans & removals',
        allowed: true,
      },
      {
        title: 'All usernames',
        allowed: true,
      },
      {
        title: 'Unlimited removals on Google, 1.7M+ Websites, Bing, X.com, Reddit, Impersonators +',
        allowed: true,
      },
    ],
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_MEDIUM_MONTHLY,
      price: 99
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_MEDIUM_YEARLY,
      price: 79
    }
  },
  {
    plan: 'large',
    title: 'Top 1%',
    description: 'Unbeatable protection engineered for the top 1%.',
    features: [
      {
        title: '<b>Hourly</b> scans & removals',
        allowed: true,
      },
      {
        title: 'All usernames',
        allowed: true,
      },
      {
        title: 'Unlimited removals on Google, 1.7M+ Websites, Bing, All social media, Impersonators +',
        allowed: true,
      },
    ],
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_LARGE_MONTHLY,
      price: 249
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_LARGE_YEARLY,
      price: 199
    }
  }
]
export const snippets = [
  'All content is under copyright. Enforced by bruqi.com.',
  'All content is under copyright. Reproduction or distribution without permission is illegal. Enforced by bruqi.com.',
]
export const getHeaderText = (usernames, links) => {
  return `These images and videos are copyrighted materials belonging to content creator performing under ${usernames.join(', ')} stagenames. The content creator distributes their content on official platforms only and strictly forbids any form of distribution outside of these platforms - ${links.join(', ')}.\n\nJames Robinson\n`
}

export const PLATFORMS = [
  {
    title: 'OnlyFans',
    domain: 'onlyfans.com',
    subscription: true
  },
  {
    title: 'Fansly',
    domain: 'fansly.com',
    subscription: true
  },
  {
    title: 'Fanvue',
    domain: 'fanvue.com',
    subscription: true
  },
  {
    title: 'Chaturbate',
    domain: 'chaturbate.com',
    subscription: true
  },
  {
    title: 'Cam4',
    domain: 'cam4.com',
    subscription: true
  },
  {
    title: 'Patreon',
    domain: 'patreon.com',
    subscription: true
  },
  {
    title: 'ManyVids',
    domain: 'manyvids.com',
    subscription: true
  },
  {
    title: 'JustForFans',
    domain: 'justfor.fans',
    subscription: true
  },
  {
    title: 'Bressels',
    domain: 'brezzels.com',
    subscription: true
  },
  {
    title: 'Mym.fans',
    domain: 'mym.fans',
    subscription: true
  },
  {
    title: '4Based',
    domain: '4based.com',
    subscription: true
  },
  {
    title: 'LoyalFans',
    domain: 'loyalfans.com',
    subscription: true
  },
  {
    title: 'Slushy',
    domain: 'slushy.com',
    subscription: true
  },
  {
    title: 'instagram',
    domain: 'instagram.com'
  },
  {
    title: 'tiktok',
    domain: 'tiktok.com'
  },
  {
    title: 'x.com',
    domain: 'x.com'
  },
  {
    title: 'Reddit (u/username)',
    domain: 'reddit.com',
    baseUrl: 'reddit.com/u/'
  },
  {
    title: 'Reddit (r/subreddit)',
    domain: 'reddit.com',
    baseUrl: 'reddit.com/r/',
    placeholder: 'your-subreddit'
  },
  {
    title: 'snapchat',
    domain: 'snapchat.com'
  }
].map((platform)  => {
  platform.baseUrl = platform.baseUrl || platform.domain
  !platform.baseUrl.endsWith('/') && (platform.baseUrl += '/')
  platform.placeholder = platform.placeholder || 'your-username'
  return platform
})

export const USERNAME_TYPES = [
  { id: 'display_name', title: 'Display Name' },
  { id: 'nickname', title: 'Nickname' },
  { id: 'real_name', title: 'Real Name' },
  { id: 'stage_name', title: 'Stage Name' },
  { id: 'old_account', title: 'Old Account' },
  { id: 'legacy', title: 'Legacy', active: false }
]
export const searchScanTypes = ['welcome', 'search', 'weekly', 'daily', 'hourly']
